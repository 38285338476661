import './App.css'
import React from 'react'
import Lottie from 'react-lottie'
import animationData from './ZhCdzB6VI7.json'
import rippleEffectData from './animation_lk0s7551.json'

function App() {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const RippleEffect = {
    loop: true,
    autoplay: true,
    animationData: rippleEffectData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div className="loader-main">
      <div className="effect">
        <Lottie options={RippleEffect} width={200} height={200} />
        <div className="logo">
          <img src="logo.png" alt="" width={70} />
        </div>
      </div>

      <h1>Payment is processing...</h1>
      <Lottie
        options={lottieOptions}
        width={150}
        height={100}
        style={{ marginTop: -10 }}
      />
    </div>
  )
}

export default App
